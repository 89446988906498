<template>
    <div style="position: relative;">
        <div class="main">
            <van-tabs v-model="tabActiveIndex" :swipeable="true" @click="changeAct">
                <van-tab v-for="(item,index) in navList" :key="index" :title="item.rangeEnd && item.rangeEnd > 0  ? '￡'+item.rangeBegin+' - '+'￡'+item.rangeEnd : '￡'+item.rangeBegin +' +'" :title-style="{fontSize:'12px'}">
                    <div class="margin-top:15px;">
                        <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="沒有更多了"
                            @load="onLoad"
                            style="padding: 10px 10px;"
                        >
                            <div v-for="(item,index) in prizeData" :key="index" style="margin-bottom:20px;">
                                <van-row class="main-body">
                                    <van-col span="17">
                                        <van-card
                                        :title="item.goodsName"
                                        :desc="item.goodsNameEn"
                                        currency='￡'
                                        @click-thumb="changeImg($event,item)"
                                    >
                                        <template #thumb>
                                            <van-image  :src="item.cover" width="100%" height="100%"/>
                                        </template>
                                        <template #tags>
                                            <span style="font-size:12px;color:#888">規格：{{item.unit}}</span>
                                            <div><span style="font-size:12px;" :style="{color:item.surplusStock == 0 ? 'red' : '#888'}">{{item.surplusStock == 0 ? 'out of stock' : item.surplusStock > 20 ? 'In Stock' : 'Stock: ' + item.surplusStock}}</span></div>
                                            <div class="card_price">
                                                <div>
                                                    <span style="color:#72B950;">￡<span style="font-size:14px;">{{item.price}}</span></span>
                                                    <span v-if="item.vat == '1'" class="vatclass"> +VAT</span>
                                                </div>
                                                <div style="text-align:right;">
                                                    <span>Total:<span style="color:#72B950;">￡<span style="font-size:14px;">{{item.finalPrice}}</span></span></span>
                                                </div>
                                            </div>
                                        </template>
                                        </van-card>
                                    </van-col>
                                    <van-col span="6">
                                        <div class="button-right">
                                            <van-button  style="width:80px;background: #FEC501;color:#fff;border-radius:10px;fonyt-size:12px;" @click="redeemGoods(item)"
                                            :disabled="$store.state.wallet < item.finalPrice || item.surplusStock == 0">兌換<br/>Redeem</van-button>
                                        </div>
                                    </van-col>
                                </van-row>
                            </div>
                        </van-list>
                    </div>
                </van-tab> 
            </van-tabs>
        </div>
        <van-dialog v-model="redeemDialog" :showConfirmButton="false" :closeOnClickOverlay="false">
            <div class="closeDialog" @click="closeDialog" v-show="!redeemStatus"><van-icon name="cross" /></div>
            <div class="main_dialog" v-show="!redeemStatus">
                <div class="dialog_title ">
                    <span><span style="font-weight:500;">確認</span><br/>Confirmation</span>
                </div>
                <div class="dialog_body">
                    <div class="dislog_container">
                        <van-row class="magin_20">
                            <van-col span="15" class="text_left"><span class="container_title">錢包</span><br/><span class="container_des">Wallet</span></van-col>
                            <van-col span="9" class="text_right"><span class="symbol yellow_font">￡</span><span class="container_price yellow_font">{{$store.state.wallet}}</span></van-col>
                        </van-row>
                        <van-row class="magin_20">
                            <van-col span="15" class="text_left"><span class="container_title">兌換{{redeemObj.goodsName}}</span><br/><span class="container_des">Redeem {{redeemObj.goodsNameEn}}</span></van-col>
                            <van-col span="9" class="text_right"><span class="symbol yellow_font">￡</span><span class="container_price yellow_font">{{redeemObj.finalPrice}}</span></van-col>
                        </van-row>
                        <van-row class="magin_20">
                            <van-col span="15" class="text_left"><span class="container_title">餘額</span><br/><span class="container_des">Balance</span></van-col>
                            <van-col span="9" class="text_right"><span class="symbol green_font">￡</span><span class="container_price green_font">{{Math.floor(($store.state.wallet - redeemObj.finalPrice)*100)/100}}</span></van-col>
                        </van-row>
                        <van-row class="magin_20">
                            <van-col class="dialog_remark">
                                獎品一經兌換不能更改、刪除和退回<br/>
                                公司保留最終解釋權
                            </van-col>
                        </van-row>
                        <van-row class="magin_20">
                            <van-col span="24" style="margin:0 auto;text-align: center;">
                                <van-button  style="width:150px;background: #FEC501;color:#000;border-radius:25px;fonyt-size:16px;" @click="confirmRedeem()">確認<br/>Confirm</van-button>
                            </van-col>
                            <van-col class="dialog_remark magin-t-20" span="24">
                                <span @click="redeemClause" style="color:#FEC501;text-decoration: underline;font-size:12px;">兌換條款 <span style="margin-left:10px;">Terms & Condition</span></span>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </div>
            <div class="main_dialog dialog_height" v-show="redeemStatus">
                <div class="dialog_title ">
                    <span><span style="font-weight:500;">條款及細則</span><br/>Terms and Conditions</span>
                </div>
                <div class="dialog_body">
                    <div class="dislog_container">
                        <van-row class="magin_20 tip-content">
                            <van-col span="24">The prizes are subjected to availability. Pacific Foods reserves the right to hold void, cancel, suspend, substitute, or amend the prizes whenever it desires without giving notice.</van-col>
                            <van-col span="24">所有奖品视乎供应情况而定，Pacific Foods有权再没有通知的情况下取消、替代、更改奖品。</van-col>
                            <van-col span="24">The decision of Pacific Foods regarding any aspect of the Lucky Wheel will be final and binding. No correspondence will be entered into about it.</van-col>
                            <van-col span="24">Pacific Foods拥有最终决定权并不得进行任何交涉和争辩。</van-col>
                        </van-row>
                        <van-row class="magin_20 toast-confirm">
                            <div @click="redeemStatus = false">確認<br>Confirm</div>
                        </van-row>
                    </div>
                </div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import {getWalletNav,getWalletGoods,redeemWalletGoods} from "../../api/wallet"
import { ImagePreview ,Notify } from 'vant';
export default {
    data() {
        return {
            loading: false,
            finished:false,
            limit: 10,
            page: 0,
            prizeData:[],
            tabActiveIndex:0,
            tabActive:'',
            navList:[],
            isFrist:false,
            redeemDialog:false,
            redeemObj:{},
            redeemStatus:false
        }
    },
    created() {
        this.$store.dispatch('getWallet')
        this.getWalletNav()
    },
    methods:{ 
        getWalletNav() {
            getWalletNav().then(res => {
                if(res.code == 200) {
                    this.navList = res.data.list;
                    if(this.navList.length > 0) {
                        this.tabActiveIndex = 0;
                        this.tabActive = this.navList[0].rangeEnd && this.navList[0].rangeEnd > 0 ? '￡'+this.navList[0].rangeBegin+' - '+'￡'+ this.navList[0].rangeEnd : '￡'+this.navList[0].rangeBegin+ ' +';
                        this.page++;
                        this.getWalletGoods()
                    }
                }
            })
        },
        getWalletGoods() {
            let tablist = '';
            let name = JSON.parse(JSON.stringify(this.tabActive))
            name = name.replace(/\s*/g,"").replace(/￡/g,"")
            if(name.indexOf('+') > -1) {
                tablist = name.split('+')
            }else {
                tablist = name.split('-')
            }
            let params = {
                limit: this.limit,
                page: this.page,
                rangeBegin: tablist[0]*1,
                rangeEnd: tablist[1]?tablist[1]*1 : -1
            }
            getWalletGoods(params).then(res => {
                this.isFrist = true;
                this.loading = false;
                this.prizeData = this.prizeData.concat(res.data.list)
                if (res.data.list.length  == 0) { 
                    this.finished = true;
                }else{
                    this.finished = false;
                }
            })
        },
        onLoad() {
            if(this.isFrist && this.tabActive) {
                this.page++;
                this.getWalletGoods()
            }
        },
        changeAct(index,title) {
            this.tabActive = title;
            this.tabActiveIndex = index;
            this.prizeData = [];
            this.page = 0;
            this.loading = true;
            this.finished = false;
            if(this.loading){
                this.onLoad();
            }
        },
        changeImg(e,item) {
            if ( e && e.stopPropagation ) {
                e.stopPropagation(); 
            }else {
                window.event.cancelBubble = true; 
            }
            ImagePreview([item.cover]);
        },
        redeemGoods(item) {
            this.redeemObj = [];
            this.redeemObj = item;
            this.redeemDialog = true;
        },
        confirmRedeem() {
            redeemWalletGoods({goodsId:this.redeemObj.id,time:new Date()}).then(res => {
                if(res.code == 200) {
                    Notify({ type: 'success', message: '兌換成功' });
                    this.$store.dispatch('getWallet')
                    this.page = 1;
                    this.prizeData = [];
                    this.getWalletGoods()
                }else {
                    Notify({ type: 'danger', message: '兌換失敗' });
                }
                this.redeemDialog = false;
            })
        },
        redeemClause() {
            this.redeemStatus = true;
        },
        closeDialog() {
            this.redeemDialog = false;
        }
    }
}
</script>
<style scoped>
    .main {
        width: 93%;
        margin: 15px auto;
        box-sizing: border-box;
        background: #fff;
        border-radius: 30px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    }
    .detail_container {
        margin-top: 30px;
        margin-bottom: 60px;
        padding: 40px 0px;
        background:#fff;
        border-radius: 20px;
        box-sizing: border-box;
    }
    .detail_flex {
        display: flex;
        justify-content: space-between;
    }
    /deep/ .van-tabs__nav {
        z-index: 1;
    }
    /deep/ .van-tabs__line {
        background: #FEDE4C;
        height: 26px;
        border-radius: 13px;
        width: 23%;
        position: absolute;
        bottom: 40%;
        z-index: -1;
    }
    .vatclass {
        font-size: 12px;
        color: #F7A92E;
    }
    .card_price {
       display: flex;
       justify-content: space-between;
       align-items: flex-end;
    }
    /deep/ .van-ellipsis {
        text-overflow: initial;
        white-space: initial;
    }
    /deep/ .van-card__desc {
        max-height: initial;
        color: #777777;
        line-height: initial;
        font-size: 14px;
    }
    /deep/ .van-card__title {
        color: #000;
        font-weight: 600;
    }
    /deep/ .van-card {
        background: #fff;
        padding: 8px 15px 8px 0;
    }
    /deep/ .van-card__thumb{
        width: 2.5rem;
        height: 2.5rem;
    }
    .main-body {
        position: relative;
        box-sizing: border-box;
    }
    .button-right {
        position: absolute;
        top:20%;
        right: 0;
    }
    .main_dialog {
        padding: 40px 27px;
    }
    .dialog_title {
        color: #FFB400;
        font-size: 20px;
        text-align: center;
    }
    .dialog_body {
        background: url('../../assets/image/yellowbg.png') no-repeat;
        background-size: 100% 100%;
    }
    .dislog_container {
        padding: 50px 50px 20px 50px;
    }
    .container_title {
        color: #000;
        font-size: 18px;
    }
    .container_des {
        color: #999999;
        font-size: 14px;
    }
    .container_price {
        font-size: 18px;
    }
    .symbol {
        font-size: 15px;
    }
    .yellow_font {
        color: #FEC501;
    }
    .green_font {
        color: #72B950;
    }
    .text_left {
        text-align: left;
    }
    .text_right {
        text-align: right;
    }
    .dialog_remark {
        font-size: 14px;
        text-align: center;
    }
    .magin_20 {
        margin-bottom: 25px;
    }
    .magin-t-20 {
        margin-top: 20px;
    }
    .dialog_height {
        max-height: 1050px;
        overflow-y: scroll;
    }
    .tip-content {
        font-size: 14px;
        color: #F7A92E ;
        line-height: 1.5;
        text-indent:50px;
    }
    .toast-confirm div {
        background-image: linear-gradient(90deg, rgb(255, 206, 56), rgb(247, 169, 46));
        box-shadow: 0px 4px 0px 0px rgba(174, 34, 5, 0.7);
        min-width: 200px;
        max-height:90px;
        border-radius: 44px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .closeDialog {
        font-size: 25px;
        color: #909399;
        position: absolute;
        top: 3%;
        right: 4%;
    }
</style>