import request from '@/utils/request'

export function getWalletList(data) {
    return request({
      url: '/app/mallaccount/flow',
      method: 'post',
      data:data
    })
}
 
// 钱包奖品商城金额导航

export function getWalletNav(data) {
  return request({
    url: '/app/wallet/priceNav',
    method: 'get'
  })
}

//根据价格区间获取钱包商城商品

export function getWalletGoods(data) {
  return request({
    url: '/app/wallet/goods',
    method: 'post',
    data:data
  })
}

//兑换奖品

export function redeemWalletGoods(data) {
  return request({
    url: '/app/wallet/redeem',
    method: 'post',
    data:data
  })
}
